import axios from "axios"
import {Buffer} from 'buffer';
import config from "../service/config"


let headers = {
    'Accept': 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
}

let myAxios = axios.create({
    baseURL: config.API_URL,
    headers: headers,
    // auth: {
    //     username: process.env.GATSBY_DRUPAL_USERNAME,
    //     password: process.env.GATSBY_DRUPAL_PASSWORD
    // }
})

myAxios.interceptors.request.use(function (config) {
    
    return config;
}, function(error) {
    return Promise.reject(error);
});

myAxios.interceptors.response.use(function(response) {
    return response;
}, function(error) {
    return Promise.reject(error)
});




const api ={

    loginUser(loginDetails)
    {
        return new Promise((resolve, reject) => {
            myAxios.post(config.LOGIN, loginDetails)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },
    registerUser(registerDetails)
    {
        return new Promise((resolve, reject) => {
            myAxios.post(config.REGISTER, registerDetails)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },
    sendOTP(payload)
    {
        return new Promise((resolve, reject) => {
            myAxios.post(config.SEND_OTP, payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },
    verifyOTP(otp){
        return new Promise((resolve, reject) => {
            myAxios.get(config.VERIFY_OTP+'/'+otp)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },
    sendEmailOTP(payload){
        return new Promise((resolve, reject) => {
            myAxios.post(config.SEND_EMAIL_OTP, payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },
    verifyEmailOTP(otp){
        return new Promise((resolve, reject) => {
            myAxios.get(config.VERIFY_EMAIL_OTP+'/'+otp)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },
    getUserFromHH(HH){
        return new Promise((resolve, reject) => {
            myAxios.get(config.GET_PATIENT_FROM_HH + '/' + HH)
                .then((response) => {
                    if(response.data?.Data?.length === 1) {
                        resolve(response.data)
                    } else {
                        let arr = response.data?.Data.filter(el => el?.regType === 'HH')
                        response.data.Data = arr 
                        resolve(response.data)
                    }
                })
                .catch(e => {
                    reject(e)
                })
        })
    },

    getUserFromMobileNumber(mobileNo){
        return new Promise((resolve, reject) => {
            myAxios.get(config.GET_PATIENT_FROM_MOBILE_NO + '/' + mobileNo)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },

    validateHHNumber(HH){
        return new Promise((resolve, reject) => {
            myAxios.get(config.VALIDATE_HH_NUMBER + '/' + HH)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },

    validateEXNumber(EX){
        return new Promise((resolve, reject) => {
            myAxios.get(config.VALIDATE_EX_NUMBER + '/' + EX)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },
    
    getReligions() {
        return new Promise((resolve, reject) => {
            myAxios.get(config.GET_ALL_RELIGION)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },

    getRelations() {
        return new Promise((resolve, reject) => {
            myAxios.get(config.GET_RELATION)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },

    getMaritalStatus() {
        return new Promise((resolve, reject) => {
            myAxios.get(config.GET_MARITAL_STATUS)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },

    getAllCountry() {
        return new Promise((resolve, reject) => {
            myAxios.get(config.GET_ALL_COUNTRY)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },

    getStatesByCountryId(id) {
        return new Promise((resolve, reject) => {
            myAxios.get(config.GET_STATES+'/'+id)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },

    updatePatient(payload) {
        return new Promise((resolve, reject) => {
            myAxios.post(config.UPDATE_PATIENT, payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },

    getUserFamilyHistory(id) {
        return new Promise((resolve, reject) => {
            myAxios.get(config.PROFILE.GET_FAMILY_HISTORY+'/'+id)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },

    postUserFamilyHistory(payload) {
        return new Promise((resolve, reject) => {
            myAxios.post(config.PROFILE.POST_FAMILY_HISTORY, payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },

    getUserPastHistory(id) {
        return new Promise((resolve, reject) => {
            myAxios.get(config.PROFILE.GET_PAST_HISTORY+'/'+id)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },

    postUserPastHistory(payload) {
        return new Promise((resolve, reject) => {
            myAxios.post(config.PROFILE.POST_PAST_HISTORY, payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },

    getUserLifestyleHistory(id) {
        return new Promise((resolve, reject) => {
            myAxios.get(config.PROFILE.GET_LIFESTYLE_HISTORY+'/'+id)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },

    postUserLifestyleHistory(payload) {
        return new Promise((resolve, reject) => {
            myAxios.post(config.PROFILE.POST_LIFESTYLE_HISTORY, payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },

    regImgUpload(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            const fileByteArray = [];
            reader.onload = (e) => {
                if (e.target.readyState == FileReader.DONE) {
                    const arrayBuffer = e.target.result,
                        array = new Uint8Array(arrayBuffer);
                    for (let i = 0; i < array.length; i++) {
                        fileByteArray.push(array[i]);
                    }
                    axios.post(config.API_URL + config.REG_FILE_UPLOAD, Buffer.from(fileByteArray), {
                        headers: { 
                            "Content-Type": "application/octet-stream", 
                            "Content-Disposition": `file; filename="${file.name}"; size:${file.size}`,
                            'Username': 'admin',
                            'Password': 'admin123'
                        }
                    })
                    .then(r => {
                        resolve(r.data)
                    })
                    .catch(err => {
                        reject(err)
                    })
                }
            }
            reader.readAsArrayBuffer(file);
        })
    },

}

export default api